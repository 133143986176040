/* styling for STACK LARGE */
/* .Stack {
  .stack {
    margin-top: -16px;
  }

  .content {
    .tech-text {
      display: inline-block;
      max-width: fit-content;
      height: fit-content;
      font-size: clamp(22px, 22px, 24px);
      white-space: nowrap;
      margin-bottom: 50px;
    }

    .lg {
      border-right: 3px solid rgba(0, 0, 0, 0.5);
      padding-right: 1rem;
      margin-right: 30px;
    }

    .icon {
      margin-right: 40px;
      margin-bottom: 35px; 
    }

    .icon-lg {
      height: 180px;
      width: auto;
      display: flex;
      flex-wrap: wrap; 
    }

    .stack-container {
      justify-content: center;
      align-items: center;
    }
  }

  .stack-icon {
    transition: transform 0.4s ease;
    height: 50px;
    width: auto;
    object-fit: contain;

    &:hover {
      transform: translateY(-1em);
    }
  }
}

.Stack {
  .content {
    .sm {
      border-bottom: 3px solid rgba(0, 0, 0, 0.5);
      padding: 5px;
      margin-bottom: clamp(35px, 40px, 45px);
      margin-top: clamp(50px, 55px, 60px);
    }

    .icon-sm {
      .stack-icon {
        transition: transform 0.4s ease;
        margin-bottom: 35px;
        margin-left: 8px;
        margin-right: 8px;
        height: 50px;
        width: auto;
        object-fit: contain;

        &:hover {
          transform: translateY(-1em);
        }
      }
    }
  }

}

@media (max-width: 767px) {
  .icon-container {
    margin-right: 0;
    margin-bottom: 10px;
  }
} */

.Stack {
  .tech-text {
    display: inline-block;
    max-width: fit-content;
    height: fit-content;
    font-size: clamp(22px, 22px, 24px);
    white-space: nowrap;
    margin-bottom: 50px;
  }

  .content {
    .sm {
      border-bottom: 3px solid rgba(0, 0, 0, 0.5);
      padding: 5px;
      margin-bottom: clamp(35px, 40px, 45px);
      margin-top: clamp(50px, 55px, 60px);
    }

    .icon-sm {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2vw;
      column-gap: 40px;

      .icon {
        margin-bottom: 35px; 
      }
      
      .stack-icon {
        transition: transform 0.4s ease;
        height: 50px;
        width: auto;
        object-fit: contain;

        &:hover {
          transform: translateY(-1em);
        }
      }
    }
  }
}
