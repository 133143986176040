.contact {
  .contact-head {
    padding: 40px 0;
    margin: 0;
  }

  .input {
    height: 50px;
  }

  .contact-me {
    font-size: clamp(22px, 26px, 32px);
  }

  .input-box {
    width: 100%;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 0.67px solid rgb(222, 226, 230);
  }

  .textarea-box {
    width: 100%;
    height: 250px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 0.67px solid rgb(222, 226, 230);
  }

  .btn-contact {
    background-color: #1060ee;
    height: 55px;
    width: 235px;
    margin-top: 15px;
    font-size: 20px;
    border: 3px solid #1060ee;
    color: white;
    border-radius: 5px;

    &:hover {
      color: #1060ee;
      background-color: white;
    }

    .spinner-border-sm {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .up-icon {
    float: right;
    margin-top: -35px;

    /*
      &:hover {
        content: url("../../Assets/Icons/up-icon-hover.svg");
        cursor: pointer;
      }
  
      &:active {
        content: url("../../Assets/Icons/up-icon.svg");
      } */
  }
}

@media screen and (max-width: 991px) {
  .contact-me {
    margin: 25px 0;
  }

  .map-container {
    display: flex;
    justify-content: center;
  }
}
