@media (prefers-reduced-motion: no-preference) {
  * {
    scroll-behavior: smooth;
  }
}

.App {
  background-color: white;
  font-family: "Poppins", sans-serif;
}

.custom-display {
  max-width: 65rem;
  padding-left: 1.25rem; /* px-5 */
  padding-right: 1.25rem; /* px-5 */
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  /* md breakpoint (768px) */
  .custom-display {
    padding-left: 2.5rem; /* md:px-10 */
    padding-right: 2.5rem; /* md:px-10 */
  }
}

/* Custom rule specifically for navigation max-width */
.header-display {
  max-width: 80rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gray-background {
  background-color: #f9f9f9;
}

.dark-background {
  background-color: #2d2e32;
}

/* styling for every text that have blue primary color */
.blue-color {
  color: #1060ee;
}

/* styling for every text that have font weight 600 */
.font-600 {
  font-weight: 600 !important;
}

/* styling for every section title */
.section-title {
  font-size: clamp(22px, 24px, 28px);
}

/* styling for every description text */
.description-text {
  color: rgb(97, 89, 120);
}